import { ResponseProductCategory } from "@/models/interface-v2/product-category.interface";

export const initProductCategory = (): ResponseProductCategory => {
  return {
    assetAccumulationAccountId: "",
    assetAccumulationAccountName: "",
    assetClearingAccountId: "",
    assetClearingAccountName: "",
    assetCostAccountId: "",
    assetCostAccountName: "",
    assetDepreciationAccountId: "",
    assetDepreciationAccountName: "",
    costOfSalesAccountId: "",
    costOfSalesAccountName: "",
    createdDate: "",
    expensePurchaseAccountId: "",
    expensePurchaseAccountName: "",
    id: "",
    inventoryAccountId: "",
    inventoryAccountName: "",
    jobCostingAccountId: "",
    jobCostingAccountName: "",
    modifiedDate: "",
    name: "",
    purchaseDiscountAccountId: "",
    purchaseDiscountAccountName: "",
    purchaseReturnAccountId: "",
    purchaseReturnAccountName: "",
    salesAccountId: "",
    salesAccountName: "",
    salesDiscountAccountId: "",
    salesDiscountAccountName: "",
    salesReturnAccountId: "",
    salesReturnAccountName: "",
    unbilledAccountId: "",
    unbilledAccountName: "",
  };
};