






























































































































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import {
  DEFAULT_PAGE_SIZE,
  MAXIMUM_FILE_SIZE,
} from "@/models/constant/global.constant";
import { TAX_TYPE } from "@/models/enums/tax.enum";
import { IOption } from "@/models/interface-v2/common.interface";
import { ResponseListMasterType } from "@/models/interface-v2/master.interface";
import { ResponseListProductCategory } from "@/models/interface-v2/product-category.interface";
import { ResponseListProduct } from "@/models/interface-v2/product.interface";
import { ResponseListProductUom } from "@/models/interface-v2/uom.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterTypeService } from "@/services-v2/master-type.service";
import { masterUomServices } from "@/services-v2/master-uom.service";
import { productCategoryServices } from "@/services-v2/product-category.service";
import { productService } from "@/services-v2/product.service";
import { fileServices } from "@/services/file.service";
import { initProductCategory } from "@/store/resource/product-category.resource";
import { initProduct } from "@/store/resource/product.resource";
import { numericOnly } from "@/validator/globalvalidator";
import { ResponseListContactData } from "@interface/contact.interface";
import { contactServices } from "@service/contact.service";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

const { mapMutations, mapState } = createNamespacedHelpers("productStoreV2");

export default Vue.extend({
  name: "Product",
  components: {
    CSelectTaxCode: () =>
      import(
        /*webpackPrefetch: true */ "@/components/shared/select-tax-code/CSelectTaxCode.vue"
      ),
    CSelectCuts: () =>
      import(
        /*webpackPrefetch: true */ "@/components/shared/select-cuts/CSelectCuts.vue"
      ),
  },
  mixins: [MNotificationVue],
  data() {
    this.handleSearchCategory = debounceProcess(this.handleSearchCategory, 300);
    this.onchangeFields = debounceProcess(this.onchangeFields, 300);
    this.onsearchParentProduct = debounceProcess(
      this.onsearchParentProduct,
      300
    );
    this.onsearchSupplier = debounceProcess(this.onsearchSupplier, 300);
    return {
      TAX_TYPE,
      formatNumericOnly: numericOnly,
      uploadProses: false as boolean,
      mode: "" as string | "edit" | "create",
      formRules: {
        supplier: {
          label: "lbl_supplier_name",
          name: "supplier",
          placeholder: "lbl_choose",
        },
        parentId: {
          label: "lbl_parent",
          name: "Parent",
          placeholder: "lbl_choose",
        },
        parent: {
          label: "lbl_is_parent",
          name: "Is Parent",
          placeholder: "lbl_type_here",
        },
        description: {
          label: "lbl_description",
          name: "Description",
          placeholder: "lbl_type_here",
        },
        category: {
          label: "lbl_category",
          name: "Category",
          placeholder: "lbl_type_here",
        },
        productType: {
          label: "lbl_product_type",
          name: "Product Type",
          placeholder: "lbl_type_here",
        },
        trackAsAsset: {
          label: "lbl_track_as_asset",
          name: "Track as Asset",
          placeholder: "lbl_type_here",
        },
        trackAsPurchase: {
          label: "lbl_track_as_purchase",
          name: "Track as Purchase",
          placeholder: "lbl_type_here",
        },
        trackAsSell: {
          label: "lbl_track_as_sale",
          name: "Track as Sell",
          placeholder: "lbl_type_here",
        },
        productName: {
          label: "lbl_product_name",
          name: "Product Name",
          placeholder: "lbl_type_here",
        },
        productCode: {
          label: "lbl_product_code",
          name: "Product Code",
          placeholder: "lbl_type_here",
        },
        baseUnit: {
          label: "lbl_uom",
          name: "Base Unit",
          placeholder: "lbl_type_here",
        },
        saleTaxRate: {
          label: "lbl_sales_tax_rate",
          name: "Sales Tax Rate",
          placeholder: "lbl_type_here",
        },
        purchaseTaxRate: {
          label: "lbl_purchase_tax_rate",
          name: "Purchase Tax Rate",
          placeholder: "lbl_type_here",
        },
        minimumStock: {
          label: "lbl_minimum_stock",
          name: "Minimum Stock",
          placeholder: "lbl_type_here",
        },
        CogsValue: {
          label: "lbl_cogs_value",
          name: "COGS Value",
          placeholder: "lbl_type_here",
        },
        trackAsInventory: {
          label: "lbl_track_inventory",
          name: "Track Inventory",
          placeholder: "lbl_type_here",
        },
        active: {
          label: "lbl_active",
          name: "active",
          placeholder: "lbl_type_here",
        },
        brand: {
          label: "lbl_brand",
          name: "brand",
          placeholder: "lbl_type_here",
        },
        productImage: {
          label: "lbl_product_image",
          name: "Product Image",
          placeholder: "lbl_type_here",
        },
      },
      fileList: [] as any,
      headers: {
        authorization: "Bearer " + this.$store.state.access_token,
      },
      productImage: "" as string | ArrayBuffer | null,
      dataListProductCategory: {} as ResponseListProductCategory,
      dataListProductType: [] as ResponseListMasterType[],
      dataListBaseUnit: {} as ResponseListProductUom,
      form: {
        productCategoryId: "",
        type: "",
        brand: "",
        description: "",
        name: "",
        code: "",
        baseUnit: "",
        minimumStock: null,
        image: "",
        trackAsAsset: false,
        trackAsInventory: false,
        trackAsPurchase: false,
        trackAsSell: false,
        active: false,
        parent: false,
        parentId: "",
        productAccount: {},
        supplierId: undefined as string | undefined,
        supplierCode: "",
        supplierFullName: "",
        salesTaxId: undefined as string | undefined,
        purchaseTaxId: undefined as string | undefined,
        cutsCodeId: "",
        cutsCode: "",
      },
      rules: {
        productCategoryId: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        type: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        name: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        code: [
          {
            required: false,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        baseUnit: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        trackAsAsset: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        trackAsInventory: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        trackAsPurchase: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        trackAsSell: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        active: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        parentId: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        supplierId: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        supplierCode: [
          {
            required: false,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        salesTaxId: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        purchaseTaxId: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        cutsCodeId: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
      },
      dtListProducts: {} as ResponseListProduct,
      dtOption: {
        parent: [] as IOption[],
        supplier: [] as IOption[],
      },
      loading: {
        parent: false,
        supplier: false,
      },
    };
  },
  computed: {
    getBaseUrl(): string {
      return fileServices.baseUrl();
    },
    ...mapState({
      storeProduct: (state: any) => state.theProduct,
    }),
  },
  created() {
    this.mode = this.$route.meta.mode;
    this.clearProdCache();
    this.fillForm();
    this.onsearchSupplier();
  },
  methods: {
    ...mapMutations(["SET_PRODUCT"]),
    onChangeSupplier(): void {
      const supp = this.dtOption.supplier.find(
        (x) => x.value === this.form.supplierId
      );
      if (supp) {
        this.form.supplierCode = supp.meta?.supplierNumber || "";
      }
      this.onchangeFields();
    },
    getListSupplier(
      params: RequestQueryParamsModel
    ): Promise<ResponseListContactData> {
      return contactServices.listContactData(params);
    },
    deleteImage() {
      this.SET_PRODUCT({ ...this.storeProduct, ...{ image: "" } });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onchangeCategory(): void {
      const dtProdCategory = this.dataListProductCategory.data.filter(
        (item) => item.id === this.form.productCategoryId
      )[0];
      if (dtProdCategory) {
        const dataProdAcc = {
          productAccount: {
            costOfSalesAccountId: dtProdCategory.costOfSalesAccountId,
            costOfSalesAccountName: dtProdCategory.costOfSalesAccountName,
            expensePurchaseAccountId: dtProdCategory.expensePurchaseAccountId,
            expensePurchaseAccountName:
              dtProdCategory.expensePurchaseAccountName,
            inventoryAccountId: dtProdCategory.inventoryAccountId,
            inventoryAccountName: dtProdCategory.inventoryAccountName,
            jobCostingAccountId: dtProdCategory.jobCostingAccountId,
            jobCostingAccountName: dtProdCategory.jobCostingAccountName,
            purchaseDiscountAccountId: dtProdCategory.purchaseDiscountAccountId,
            purchaseDiscountAccountName:
              dtProdCategory.purchaseDiscountAccountName,
            purchaseReturnAccountId: dtProdCategory.purchaseReturnAccountId,
            purchaseReturnAccountName: dtProdCategory.purchaseReturnAccountName,
            salesAccountId: dtProdCategory.salesAccountId,
            salesAccountName: dtProdCategory.salesAccountName,
            salesDiscountAccountId: dtProdCategory.salesDiscountAccountId,
            salesDiscountAccountName: dtProdCategory.salesDiscountAccountName,
            salesReturnAccountId: dtProdCategory.salesReturnAccountId,
            salesReturnAccountName: dtProdCategory.salesReturnAccountName,
            unbilledAccountId: dtProdCategory.unbilledAccountId,
            unbilledAccountName: dtProdCategory.unbilledAccountName,
          },
        };
        this.form.productAccount = dataProdAcc.productAccount;
        this.SET_PRODUCT({
          ...this.storeProduct,
          ...dataProdAcc,
          ...{ productCategoryId: dtProdCategory.id },
        });
      }
    },
    beforeUpload(file): boolean {
      const isLt5M = file.size;
      if (isLt5M >= MAXIMUM_FILE_SIZE) {
        this.$message.error(this.$t("lbl_upload_info_1").toString());
        return false;
      }
      return true;
    },
    handleChangeProductImage(info) {
      let valueImageProcess = "" as string;
      if (info.fileList.length < 1) {
        valueImageProcess = "";
      }
      if (info.file.status !== "uploading") {
        this.uploadProses = true;
      }
      if (info.file.status === "done") {
        this.uploadProses = false;
        valueImageProcess = info.file.response.objectName;
        this.$message.success(
          this.$t("notif_file_upload_successfully", {
            filename: info.file.name,
          }).toString()
        );
        this.getImage(valueImageProcess);
      } else if (info.file.status === "error") {
        this.uploadProses = true;
        valueImageProcess = "";
        this.$message.error(
          this.$t("notif_file_upload_failed", {
            filename: info.file.name,
          }).toString()
        );
      }
      this.SET_PRODUCT({
        ...this.storeProduct,
        ...{ image: valueImageProcess },
      });
    },
    async getImage(filename: string): Promise<void> {
      try {
        if (!filename || filename === " ") return;
        const blob = await fileServices.getFile(filename);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          this.productImage = reader.result;
        };
      } catch (error) {
        console.error(error);
      }
    },
    getListProductCategory(
      search?: string
    ): Promise<ResponseListProductCategory> {
      const params: RequestQueryParamsModel = {
        limit: DEFAULT_PAGE_SIZE,
        page: 0,
        sorts: "createdDate:desc",
      };
      if (search) params.search = `name~*${search}*`;
      return productCategoryServices.listProductCategory(params);
    },
    objToStringJson(obj: any): string {
      return JSON.stringify(obj);
    },
    strJsonToObj(str: string): any {
      return JSON.parse(str);
    },
    async getListOfProducType(): Promise<ResponseListMasterType[]> {
      let params = {
        name: "PRODUCT_TYPE",
      } as RequestQueryParamsModel;
      return masterTypeService.listMaster(params);
    },
    onchangeFields(): void {
      this.SET_PRODUCT({
        ...this.storeProduct,
        ...this.form,
        productAccount: {
          ...this.storeProduct.productAccount,
          salesTaxId: this.form.salesTaxId,
          purchaseTaxId: this.form.purchaseTaxId,
        },
      });
    },
    async getListOfBaseUnit(): Promise<ResponseListProductUom> {
      let params = {} as RequestQueryParamsModel;
      return masterUomServices.listMasterUom(params);
    },
    onchangeIsParent(checked: boolean): void {
      this.form.parentId = "";
      this.SET_PRODUCT({
        ...this.storeProduct,
        ...{ parent: checked, parentId: "" },
      });
    },
    handleItemLimit(): void {
      const prodCat = this.dataListProductCategory.data.find(
        (item) => item.id === this.form.productCategoryId
      );
      if (!prodCat) {
        this.dataListProductCategory.data.push({
          ...initProductCategory(),
          id: this.form.productCategoryId,
          name: this.storeProduct.categoryName,
        });
      }

      const prodSupplier = this.dtOption.supplier.find(
        (x) => x.value === this.storeProduct.supplierId
      );
      if (!prodSupplier) {
        this.getListSupplier({
          search: `secureId~${this.storeProduct.supplierId}`,
        }).then(({ data }: ResponseListContactData) => {
          const [sup] = data;
          this.dtOption.supplier.push({
            key: sup.fullName,
            value: sup.id,
            meta: sup,
          });
        });
      }

      const prodParent = !!this.dtOption.parent.find(
        (item) => item.value === this.form.parentId
      );
      if (prodParent || this.form.parent) return;
      this.dtOption.parent.push({
        value: this.storeProduct.parentId,
        key: this.storeProduct.parentName,
      });
    },
    async fillForm(): Promise<void> {
      try {
        this.loading.parent = true;
        const resProds = await this.getListProductsParent();
        const resProdCat = await this.getListProductCategory();
        const resProdType = await this.getListOfProducType();
        const resUnit = await this.getListOfBaseUnit();
        this.dataListProductCategory = resProdCat;
        this.dataListProductType = resProdType;
        this.dataListBaseUnit = resUnit;
        this.dtOption.parent = resProds.data.map((x) => ({
          key: x.code,
          value: x.id,
        }));
        this.setForm();
        if (this.form.image) this.getImage(this.form.image);
        this.handleItemLimit();
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading.parent = false;
      }
    },
    setForm(): void {
      const dt = this.storeProduct;
      this.form = {
        productCategoryId: dt.productCategoryId,
        type: dt.type,
        brand: dt.brand,
        description: dt.description,
        name: dt.name,
        code: dt.code,
        baseUnit: dt.baseUnit,
        minimumStock: dt.minimumStock,
        image: dt.image,
        trackAsAsset: dt.trackAsAsset,
        trackAsInventory: dt.trackAsInventory,
        trackAsPurchase: dt.trackAsPurchase,
        trackAsSell: dt.trackAsSell,
        active: dt.active,
        parent: dt.parent,
        parentId: dt.parentId,
        productAccount: dt.productAccount,
        supplierId: dt.supplierId,
        supplierFullName: dt.supplierFullName,
        purchaseTaxId: dt.productAccount.purchaseTaxId,
        salesTaxId: dt.productAccount.salesTaxId,
        supplierCode: dt.supplierCode,
        cutsCodeId: dt.cutsCodeId,
        cutsCode: dt.cutsCode,
      };
    },
    clearProdCache(): void {
      if (this.mode === "create") {
        this.SET_PRODUCT(initProduct());
      }
    },
    async getListProductsParent(): Promise<ResponseListProduct> {
      const params: RequestQueryParamsModel = {
        limit: DEFAULT_PAGE_SIZE,
        page: 0,
        search: "parent~true",
      };
      return productService.listProduct(params);
    },
    async handleSearchCategory(search = ""): Promise<void> {
      try {
        const response = await this.getListProductCategory(search);
        this.dataListProductCategory = response;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      }
    },
    async onsearchParentProduct(search = ""): Promise<void> {
      try {
        this.loading.parent = true;
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: DEFAULT_PAGE_SIZE,
        };
        if (search)
          param.search = `code~*${search}*_OR_name~*${search}*_AND_parent~true`;
        const res = await productService.listProduct(param);
        this.dtOption.parent = res.data.map((x) => ({
          key: x.code,
          value: x.id,
        }));
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.parent = false;
      }
    },
    async onsearchSupplier(search = ""): Promise<void> {
      try {
        const params: RequestQueryParamsModel = {
          page: 0,
          limit: DEFAULT_PAGE_SIZE,
          search: "supplier~true_AND_active~true",
          sorts: "firstName:asc",
        };
        if (search)
          params.search = `supplier~true_AND_active~true_AND_firstName~*${search}*`;
        this.loading.supplier = true;
        const res = await this.getListSupplier(params);
        this.dtOption.supplier = res.data.map((x) => {
          return { key: x.firstName, value: x.id, meta: x };
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.supplier = false;
      }
    },
  },
});
