var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      attrs: {
        "label-col": { sm: { span: 24 }, md: { span: 10 } },
        "wrapper-col": { sm: { span: 24 }, md: { span: 14 } },
        "label-align": "left",
        model: _vm.form,
        rules: _vm.rules
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.category.label),
                    prop: "productCategoryId"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        name: _vm.formRules.category.name,
                        placeholder: _vm.$t(_vm.formRules.category.placeholder),
                        "show-search": true,
                        "filter-option": false,
                        loading: false,
                        "allow-clear": true
                      },
                      on: {
                        change: _vm.onchangeCategory,
                        search: _vm.handleSearchCategory
                      },
                      model: {
                        value: _vm.form.productCategoryId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "productCategoryId", $$v)
                        },
                        expression: "form.productCategoryId"
                      }
                    },
                    _vm._l(_vm.dataListProductCategory.data, function(
                      data,
                      index
                    ) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.id } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.name) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.name) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.productType.label),
                    prop: "type"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        name: _vm.formRules.productType.name,
                        placeholder: _vm.$t(
                          _vm.formRules.productType.placeholder
                        ),
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        loading: false,
                        "allow-clear": true
                      },
                      on: { change: _vm.onchangeFields },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    _vm._l(_vm.dataListProductType, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.value) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.value) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.brand.label) } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: _vm.$t(_vm.formRules.brand.placeholder)
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.brand,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "brand", $$v)
                      },
                      expression: "form.brand"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_cuts"), prop: "cutsCodeId" } },
                [
                  _c("CSelectCuts", {
                    attrs: {
                      "for-list": false,
                      "prop-cuts-code": _vm.form.cutsCode
                    },
                    on: { "on-select": _vm.onchangeFields },
                    model: {
                      value: _vm.form.cutsCodeId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cutsCodeId", $$v)
                      },
                      expression: "form.cutsCodeId"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.productName.label),
                    prop: "name"
                  }
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: _vm.$t(_vm.formRules.productName.placeholder)
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.productCode.label),
                    prop: "code"
                  }
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: _vm.$t(_vm.formRules.productCode.placeholder)
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.code,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.baseUnit.label),
                    prop: "baseUnit"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        name: _vm.formRules.baseUnit.name,
                        placeholder: _vm.$t(_vm.formRules.baseUnit.placeholder),
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        "allow-clear": true
                      },
                      on: { change: _vm.onchangeFields },
                      model: {
                        value: _vm.form.baseUnit,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "baseUnit", $$v)
                        },
                        expression: "form.baseUnit"
                      }
                    },
                    _vm._l(_vm.dataListBaseUnit.data, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.unit } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.unit) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.unit) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.saleTaxRate.label) } },
                [
                  _c("CSelectTaxCode", {
                    attrs: { "tax-type": _vm.TAX_TYPE.VAT_OUT },
                    on: { "on-select": _vm.onchangeFields },
                    model: {
                      value: _vm.form.salesTaxId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "salesTaxId", $$v)
                      },
                      expression: "form.salesTaxId"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: { label: _vm.$t(_vm.formRules.purchaseTaxRate.label) }
                },
                [
                  _c("CSelectTaxCode", {
                    attrs: { "tax-type": _vm.TAX_TYPE.VAT_IN },
                    on: { "on-select": _vm.onchangeFields },
                    model: {
                      value: _vm.form.purchaseTaxId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "purchaseTaxId", $$v)
                      },
                      expression: "form.purchaseTaxId"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.parent.label) } },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": _vm.$t("lbl_yes"),
                      "un-checked-children": _vm.$t("lbl_no"),
                      name: _vm.formRules.parent.name
                    },
                    on: { change: _vm.onchangeIsParent },
                    model: {
                      value: _vm.form.parent,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "parent", $$v)
                      },
                      expression: "form.parent"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.parentId.label),
                    prop: _vm.form.parent ? "" : "parentId"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        name: _vm.formRules.parentId.name,
                        placeholder: _vm.$t(_vm.formRules.parentId.placeholder),
                        "show-search": true,
                        "filter-option": false,
                        loading: _vm.loading.parent,
                        "allow-clear": true,
                        disabled: _vm.form.parent,
                        "dropdown-match-select-width": false
                      },
                      on: {
                        change: _vm.onchangeFields,
                        search: _vm.onsearchParentProduct
                      },
                      model: {
                        value: _vm.form.parentId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "parentId", $$v)
                        },
                        expression: "form.parentId"
                      }
                    },
                    _vm._l(_vm.dtOption.parent, function(data, index) {
                      return _c(
                        "a-select-option",
                        {
                          key: index,
                          attrs: {
                            value: data.value,
                            disabled: _vm.storeProduct.id === data.value
                          }
                        },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.key || "-") + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.key || "-") + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.supplier.label),
                    prop: "supplierId"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        name: _vm.formRules.supplier.name,
                        placeholder: _vm.$t(_vm.formRules.supplier.placeholder),
                        "show-search": true,
                        "filter-option": false,
                        loading: _vm.loading.supplier,
                        "allow-clear": true,
                        "dropdown-match-select-width": false
                      },
                      on: {
                        change: _vm.onChangeSupplier,
                        search: _vm.onsearchSupplier
                      },
                      model: {
                        value: _vm.form.supplierId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "supplierId", $$v)
                        },
                        expression: "form.supplierId"
                      }
                    },
                    _vm._l(_vm.dtOption.supplier, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.key || "-") + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.key || "-") + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_supplier_code"),
                    prop: "supplierCode"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { "read-only": "" },
                    model: {
                      value: _vm.form.supplierCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "supplierCode", $$v)
                      },
                      expression: "form.supplierCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.minimumStock.label) } },
                [
                  _c("a-input-number", {
                    attrs: {
                      placeholder: _vm.$t(
                        _vm.formRules.minimumStock.placeholder
                      ),
                      parser: _vm.formatNumericOnly,
                      min: 0
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.minimumStock,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "minimumStock", $$v)
                      },
                      expression: "form.minimumStock"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.description.label) } },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: _vm.$t(
                        _vm.formRules.description.placeholder
                      ),
                      rows: 4
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              ),
              !_vm.storeProduct.image
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.productImage.label) }
                    },
                    [
                      _c(
                        "a-upload",
                        {
                          attrs: {
                            action: _vm.getBaseUrl,
                            "before-upload": _vm.beforeUpload,
                            name: "file",
                            multiple: false,
                            accept: "image",
                            headers: _vm.headers
                          },
                          on: { change: _vm.handleChangeProductImage }
                        },
                        [
                          _c("a-button", { attrs: { icon: "upload" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_upload")) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.productImage.label) }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.productImage,
                          alt: "image",
                          width: "100%",
                          height: "auto"
                        }
                      }),
                      _c("a-button", {
                        attrs: { icon: "delete", type: "danger" },
                        on: { click: _vm.deleteImage }
                      })
                    ],
                    1
                  ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.trackAsInventory.label),
                    prop: "trackAsInventory"
                  }
                },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": _vm.$t("lbl_yes"),
                      "un-checked-children": _vm.$t("lbl_no"),
                      name: _vm.formRules.trackAsInventory.name
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.trackAsInventory,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "trackAsInventory", $$v)
                      },
                      expression: "form.trackAsInventory"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.trackAsAsset.label),
                    prop: "trackAsAsset"
                  }
                },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": _vm.$t("lbl_yes"),
                      "un-checked-children": _vm.$t("lbl_no"),
                      name: _vm.formRules.trackAsAsset.name
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.trackAsAsset,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "trackAsAsset", $$v)
                      },
                      expression: "form.trackAsAsset"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.trackAsPurchase.label),
                    prop: "trackAsPurchase"
                  }
                },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": _vm.$t("lbl_yes"),
                      "un-checked-children": _vm.$t("lbl_no"),
                      name: _vm.formRules.trackAsPurchase.name
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.trackAsPurchase,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "trackAsPurchase", $$v)
                      },
                      expression: "form.trackAsPurchase"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.trackAsSell.label),
                    prop: "trackAsSell"
                  }
                },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": _vm.$t("lbl_yes"),
                      "un-checked-children": _vm.$t("lbl_no"),
                      name: _vm.formRules.trackAsSell.name
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.trackAsSell,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "trackAsSell", $$v)
                      },
                      expression: "form.trackAsSell"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.active.label),
                    prop: "active"
                  }
                },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": _vm.$t("lbl_yes"),
                      "un-checked-children": _vm.$t("lbl_no"),
                      name: _vm.formRules.active.name
                    },
                    on: { change: _vm.onchangeFields },
                    model: {
                      value: _vm.form.active,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "active", $$v)
                      },
                      expression: "form.active"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }